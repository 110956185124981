import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WalletIcon from "@mui/icons-material/Wallet";
import ForumIcon from "@mui/icons-material/Forum";
import { Tooltip, Button } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PersonIcon from "@mui/icons-material/Person";
import PsychologyIcon from "@mui/icons-material/Psychology";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import GroupsIcon from "@mui/icons-material/Groups";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./redux/user";
import PendingIcon from "@mui/icons-material/Pending";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function Main() {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const { currentUser } = useSelector((state) => state.admin);
	console.log(currentUser);
	const dispatch = useDispatch();
	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: "none" }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
						اكادميتنا
					</Typography>
					<Button
						color="secondary"
						variant="contained"
						onClick={() => dispatch(logout())}
					>
						تسجيل الخروج
					</Button>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{currentUser.course && (
						<Link style={{ textDecoration: "none", color: "#000" }} to={"courses"}>
							<ListItem disablePadding sx={{ display: "block" }}>
								<Tooltip title={open ? "" : "الدورات"} placement="left">
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: open ? "initial" : "center",
											px: 2.5,
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : "auto",
												justifyContent: "center",
											}}
										>
											<LaptopChromebookIcon />
										</ListItemIcon>
										<ListItemText primary={"الدورات"} sx={{ opacity: open ? 1 : 0 }} />
									</ListItemButton>
								</Tooltip>
							</ListItem>
						</Link>
					)}
					{currentUser.group && (
						<Link style={{ textDecoration: "none", color: "#000" }} to={"groups"}>
							<ListItem disablePadding sx={{ display: "block" }}>
								<Tooltip title={open ? "" : "المجموعات"} placement="left">
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: open ? "initial" : "center",
											px: 2.5,
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : "auto",
												justifyContent: "center",
											}}
										>
											<GroupsIcon />
										</ListItemIcon>
										<ListItemText primary={"المجموعات"} sx={{ opacity: open ? 1 : 0 }} />
									</ListItemButton>
								</Tooltip>
							</ListItem>
						</Link>
					)}
					
					<Link style={{ textDecoration: "none", color: "#000" }} to={"students"}>
						<ListItem disablePadding sx={{ display: "block" }}>
							<Tooltip title={open ? "" : "الطلاب"} placement="left">
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										<PersonIcon />
									</ListItemIcon>
									<ListItemText primary={"الطلاب"} sx={{ opacity: open ? 1 : 0 }} />
								</ListItemButton>
							</Tooltip>
						</ListItem>
					</Link>
					
					<Link style={{ textDecoration: "none", color: "#000" }} to={"subjects"}>
						<ListItem disablePadding sx={{ display: "block" }}>
							<Tooltip title={open ? "" : "المواد"} placement="left">
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										<MenuBookIcon />
									</ListItemIcon>
									<ListItemText primary={"المواد"} sx={{ opacity: open ? 1 : 0 }} />
								</ListItemButton>
							</Tooltip>
						</ListItem>
					</Link>
					
					<Link style={{ textDecoration: "none", color: "#000" }} to={"wallets"}>
						<ListItem disablePadding sx={{ display: "block" }}>
							<Tooltip title={open ? "" : "جدول المحفظة"} placement="left">
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										<WalletIcon />
									</ListItemIcon>
									<ListItemText
										primary={"جدول المحفظة"}
										sx={{ opacity: open ? 1 : 0 }}
									/>
								</ListItemButton>
							</Tooltip>
						</ListItem>
					</Link>
					{currentUser.psycho && (
						<Link
							style={{ textDecoration: "none", color: "#000" }}
							to={"psychologist"}
						>
							<ListItem disablePadding sx={{ display: "block" }}>
								<Tooltip title={open ? "" : "الأخصائي النفسي"} placement="left">
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: open ? "initial" : "center",
											px: 2.5,
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : "auto",
												justifyContent: "center",
											}}
										>
											<PsychologyIcon />
										</ListItemIcon>
										<ListItemText
											primary={"الأخصائي النفسي"}
											sx={{ opacity: open ? 1 : 0 }}
										/>
									</ListItemButton>
								</Tooltip>
							</ListItem>
						</Link>
					)}
					{currentUser.forum && (
						<Link style={{ textDecoration: "none", color: "#000" }} to={"forums"}>
							<ListItem disablePadding sx={{ display: "block" }}>
								<Tooltip title={open ? "" : "النوادي"} placement="left">
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: open ? "initial" : "center",
											px: 2.5,
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : "auto",
												justifyContent: "center",
											}}
										>
											<ForumIcon />
										</ListItemIcon>
										<ListItemText primary={"النوادي "} sx={{ opacity: open ? 1 : 0 }} />
									</ListItemButton>
								</Tooltip>
							</ListItem>
						</Link>
					)}
					
					<Link
						style={{ textDecoration: "none", color: "#000" }}
						to={"parent_requests"}
					>
						<ListItem disablePadding sx={{ display: "block" }}>
							<Tooltip title={open ? "" : "جدول طلبات الأب"} placement="left">
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										<PendingIcon />
									</ListItemIcon>
									<ListItemText
										primary={"جدول طلبات الأب"}
										sx={{ opacity: open ? 1 : 0 }}
									/>
								</ListItemButton>
							</Tooltip>
						</ListItem>
					</Link>
					
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				<Outlet />
			</Box>
		</Box>
	);
}
